<template>
  <div>
    <PageTitlebar :title="'Edit Advertiser Account - ' + advertiserAccountId">
      <template v-slot:top>
        <Link
          :to="{ name: 'AdvertiserAccountsOverview' }"
          label="Back to Accounts"
          icon="sym_r_chevron_left"
        />
      </template>
    </PageTitlebar>

    <AdvertiserAccountForm
      type="edit"
      :advertiser-account-id="advertiserAccountId"
      :submitting="submitting"
      @submit="editAdvertiserAccount"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import AdvertiserAccountForm from "@/components/Advertiser/AdvertiserAccountForm";
import Link from "@/components/UI/Link";

export default {
  name: "AdvertiserAccountEditor",
  props: {
    advertiserAccountId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    Link,
    AdvertiserAccountForm,
    PageTitlebar
  },
  data() {
    return {
      submitting: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    editAdvertiserAccount(formData) {
      this.submitting = true;

      this.$store
        .dispatch("advertiserAccounts/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: this.advertiserAccountId,
            name: formData.name,
            product_type_id: formData.productTypeId,
            bid_method: formData.bidMethod,
            static_bid_value: formData.staticBidValue,
            daily_budget: formData.dailyBudgetValue,
            country_code: formData.countryCode,
            url_tracking_parameters: formData.urlTrackingParams
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
          this.backToTop();
        })
        .catch(e => {
          console.log(e);
          if (
            e.response &&
            e.response.data.errors[0].detail &&
            e.response.data.errors[0].detail.includes("Invalid URL Parameter")
          ) {
            this.triggerActionOutcomeAlert(
              "save",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("save", false);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
